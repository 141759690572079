<template>
  <div class="pr-2">
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="600" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-badge color="green" :content="count" overlap :value="count">
          <v-btn v-bind="attrs" v-on="on" icon small>
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <widget-notifi-list v-if="menu" @reCount="checkMessage" @close="menu = false" @show="onShow($event)" />
      </v-card>
    </v-menu>
    <portal to="v-main">
      <changeLog v-if="showChangeLog" v-model="showChangeLog" :id="id"></changeLog>
    </portal>
  </div>
</template>

<script>
export default {
  components: {
    changeLog: () => import("@/views/views/changeLogView"),
  },
  data() {
    return {
      id: 0,
      showChangeLog: false,
      count: 0,
      api: "/mechti/events",
      menu: false,
    };
  },
  beforeDestroy() {},
  watch: {},
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
  created() {
    this.checkMessage();
    this.$root.$on("websocket", data => {
      if (data?.t == "new_event") this.checkMessage();
    });
  },
  destroyed() {
    this.$root.$off("websocket", this.addData);
  },
  computed: {},
  methods: {
    onShow({ target_name, target_id }) {
      this.id = 0;
      if (target_name == "ChangeLogModel") {
        this.id = target_id;
        this.showChangeLog = true;
      }
    },
    async checkMessage() {
      let resp = await this.$axios(this.api + "/count", { params: { filters: { status: 1 } } });
      this.count = resp.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.alerts {
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 99999;
}
</style>
