<template>
  <div class="d-flex" style="position: relative">
    <v-dialog ref="dialog" v-model="showPicker" persistent width="290px" :disabled="readonly">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :value="text"
          :label="model.title"
          :error-messages="error"
          :disabled="disabled"
          :hide-details="config.hideDetails || false"
          readonly
          persistent-placeholder
          placeholder="Не указано"
          :clearable="!!value && !readonly"
          :dense="config.dense || false"
          :append-icon="!value ? 'mdi-calendar' : ''"
          @click="showPicker = true"
          @click:append="showPicker = true"
          @click:clear="
            $emit('input', null);
            initValue = null;
          "
        >
        </v-text-field>
      </template>
      <a-input-base-buying-rules v-if="showPicker" :model="model" :value="value" @input="$emit('input', $event)" @close="showPicker = false" />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showPicker: false,
    };
  },
  computed: {
    services() {
      let serv = this.$root.dirs["service"] || [];
      let res = {};
      serv.forEach(el => {
        res[el.value] = el;
      });
      return res;
    },

    text: {
      get() {
        let res = "";
        if (this.value) {
          this.value.forEach(v => {
            if (v.type == 1) {
              let r;

              r = (v.services || [])
                .map(s => {
                  let t = this.services?.[s]?.text || "?";
                  return t.abbr();
                })
                .join();
              res += (res ? " и " : "") + `[${r}]`;
            }
            if (v.type == 2) {
              let r;

              r = [v.service_id || null]
                .map(s => {
                  let t = this.services?.[s]?.text || "?";
                  console.log("ddddddd", this.services?.[s], s);
                  return t.abbr(3);
                })
                .join();
              res += (res ? " ; " : "") + `[за ${v.value}д до ${r}]`;
            }
          });
        }
        return res;
      },
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    displayValue() {
      let res = this.value || null;
      return res;
    },
  },
};
</script>
