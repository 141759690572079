<template>
  <div>
    <div v-if="trumb">
      <v-card width="250" class1="mb-2" class="mb-2" @click="$emit('clickTrumb')">
        <div class="pa-3" height="50px" style="height: 50px">
          <h4>#{{ q.id }} {{ q.t }}</h4>
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card-text class="pa-2 pt-4" style="height: 60px; overflow: hidden" v-bind="attrs" v-on="on">{{ q.question.text }}</v-card-text>
          </template>
          <div style="width: 250px">{{ q.question.text }}</div>
        </v-tooltip>
      </v-card>
    </div>
    <div v-if="full" class1="mb-2" class="mx-4">
      <div class="pb-3">
        <span style="display: none">{{ q.id }}</span>
        <v-card-title class="text-h6 text-center pt-1">{{ q.t }}</v-card-title>
        <v-card-text class="px-2 py-4" style="font-size: 18px">Ознакомьтесь пожалуйста с инструкцией и ответьте на вопрос </v-card-text>
        <v-btn color="primary" @click="$emit('showInfo')"> Посмотреть инструкцию.</v-btn>
      </div>
      <hr class="mt-2 mb-6" />
      <h4>Вопрос:</h4>
      <v-card-text class="pa-2 pt-4" style="font-size: 18px">{{ q.question.text }}</v-card-text>
      <button
        v-ripple
        v-for="(a, i) in q.question.answers"
        :key="i"
        :disabled="q.done"
        class="mb-1 pa-2 myButton"
        style1="background-color: #ffbe0b"
        @click="$emit('onAnswer', a)"
      >
        {{ a.text }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trumb: Boolean,
    full: Boolean,
    q: { type: Object, default: () => {} },
  },
};
</script>
<style lang="sass">
.myButton
  color: #fff
  border-radius: 5px
  font-family: Avenir, Helvetica, Arial, sans-serif
  font-size: 1.04em
  letter-spacing: 0.05em
  width: 100%
  transition: 0.3s
  margin-top: .5rem
  display: block
  width: 100%
  color: #fff
  background-color: #818181
  border-color: #818181
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
.myButton:not([disabled]):hover
  opacity: 0.7

.myButton:disabled
  color: #fff
  background-color: rgba(0, 0, 0, 0.26)
  border-color: rgba(0, 0, 0, 0.26)
</style>
