<template>
  <div>
    <div class="row-message" :class="item.user_id !== user_id ? 'left' : 'right'">
      <div class="row-message-inner" :class="{ notviewed: item.viewedon == null && item.user_id != user_id }" @click="$emit('click', $event)">
        <div v-if="messageUser" class="pink--text">{{ messageUser }}</div>
        <div v-if="true">{{ messageText }}</div>

        <div class="row-message-inner-date">
          {{ messageDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { components } from "@/components/mixings";

export default {
  mixins: [components],
  components: {},
  props: {
    user_id: Number,
    id: Number,
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  computed: {
    el() {
      return this.item;
    },
    messageText() {
      return this.item.content;
    },
    messageUser() {
      let res;
      if (this.item.type == 2) res = !this.item.createdby_id ? "Менеджер" : this.item.user_name;
      else res = !this.item.createdby_id ? this.item.lead_name : this.item.user_name;
      return res;
    },
    messageDate() {
      const d = new Date(this.item.createdon);
      const n = new Date();
      let res = d.date == n.date ? d.shorttime : this.getDateString(d) + " " + d.shorttime;
      return res;
    },
  },
  watch: {},
  methods: {
    getDateString(d) {
      let a = new Date(d);
      return a.toLocaleDateString("ru");
    },
    getTimeString(d) {
      let a = new Date(d);
      return a.toLocaleTimeString("ru", { hour: "2-digit", minute: "2-digit" });
    },
  },
};
</script>
<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .row-message {
    .row-message-inner {
      &.notviewed {
        font-weight: bold;
      }
    }
  }
  .comments-body {
    .comment-row {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;
      &:hover {
        background: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .comment-row:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
}
.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>
