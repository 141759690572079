/**
 * /* eslint-disable no-console
 *
 *
 navigator.serviceWorker.ready.then(function(registration) {
    payload.notification.data = payload.notification; // параметры уведомления
    registration.showNotification(payload.notification.title, payload.notification);
}).catch(function(error) {
    console.log('ServiceWorker registration failed', error);
}); 
 * @format
 */

import { register } from 'register-service-worker';
import { notificationBus } from '@/libs/notificationBus';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
      //  payload.notification.data = payload.notification; // параметры уведомления
      // registration.showNotification(payload.notification.title, payload.notification);
    },
    registered(registration) {
      console.log('Service worker has been registered.');
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
      if (registration.waiting) {
        setTimeout(() => {
          notificationBus.$emit('newVersionAvailable', { registration });
        }, 2000);
      }
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      notificationBus.$emit('newVersionAvailable', { registration });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });

  /* 
  self.addEventListener('notificationclick', (event) => {
    event.notification.close(); // Закрыть уведомление

    // Перейти по URL, если он указан в data
    //console.log(event.notification);
    const urlToOpen = event.notification.data?.click_action || '/';
    event.waitUntil(
      clients.matchAll({ type: 'window', includeUncontrolled: true }).then((clientList) => {
        for (const client of clientList) {
          if (client.url === urlToOpen && 'focus' in client) {
            return client.focus();
          }
        }
        if (clients.openWindow) {
          return clients.openWindow(urlToOpen);
        }
      })
    );
  });
  */
}
