/** @format */
import modelOptions from '@/components/libs/modelOptions';

export default {
  mixins: [modelOptions],
  methods: {
    async table2xls() {
      const formatJson = (filterVal, jsonData) => {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      };

      const getDataText = (model, value) => {
        if (model.type == 'select') {
          let options = this.getOptions(model);
          if (model.chips) {
            return value.map((v) => {
              return options.find((f) => f.value == v).text;
            });
          }
          return this.getTextByValue(value, model);
        } else {
          console.log('неизвестный тип поля ', model.name, model?.type);
        }
        return value;
      };
      this.loading = true;
      this.downloadLoading = true;

      let filters = Object.assign({}, this.filters);
      //filters = Object.assign({}, where, this.filters);
      //filters = Object.assign({}, this.filters);
      let pager = Object.assign({}, this.pager);
      pager.page = 0;
      pager.limit = 1000;
      let params = { sort: JSON.stringify(this.sort), filters, pager };
      let response = await this.$axios.get(this.api, { params });
      let d = response.data.data;
      for (const row of d) {
        for (const model of this.model.filter((m) => !['text', 'string', 'number'].includes(m.type))) {
          try {
            //console.log(model);
            let res = getDataText(model, row[model.name]);
            //console.log(model.name, row[model.name], '=', res);
            row[model.name] = res;
          } catch (error) {
            //console.log(error);
          }
        }
      }
      let headerTitle = this.model.map((m) => {
        return m.title;
      });
      let headerName = this.model.map((m) => {
        return m.name;
      });
      import('@/libs/Export2Excel').then((excel) => {
        const tHeader = headerTitle;
        const filterVal = headerName;
        const data = formatJson(filterVal, d);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: true,
          maxWidth: 40,
          bookType: 'xlsx',
        });
      });

      this.loading = !true;
      this.downloadLoading = !true;
    },
  },
};
