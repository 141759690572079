/** @format */

const uploadsGroup = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 200 },
    { name: 'roles', title: 'Роли', type: 'select', dir: 'roles', sortable: true, width: 300, chips: true },
  ],
  form: [
    { name: 'name', title: 'Имя', type: 'string', validator: ['req'] },
    { name: 'roles', title: 'Роли1', type: 'selectmulti', dir: 'roles', chips: true, validator: ['req'] },
  ],
};
export default uploadsGroup;
