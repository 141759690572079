/** @format */

const settings = [
  {
    path: '/invite',
    name: 'invite',
    meta: { auth: 10, title: 'Приглашения' },
    component: () => {
      return import('@/views/invite.vue');
    },
  },
  {
    path: '/info-admin',

    name: 'info-admin',
    meta: { auth: 10, title: 'Инфо', access: 'info-admin' },
    component: () => {
      return import('@/views/admin/infoAdmin.vue');
    },
  },
  {
    path: '/apiConfig',
    name: 'apiConfig',
    meta: { auth: 10, title: 'API' },
    component: () => {
      return import('@/views/admin/apiConfig.vue');
    },
  },
  {
    path: '/editConfig',
    name: 'editConfig',
    meta: { auth: 10, title: 'Конфигуратор' },
    component: () => {
      return import('@/views/admin/editConfig.vue');
    },
  },
  {
    path: '/uploads-admin',
    name: 'uploads-admin',
    meta: { auth: 10, title: 'Загрузки' },
    component: () => {
      return import('@/views/admin/uploadsAdmin.vue');
    },
  },
  {
    path: '/logs-admin',
    name: 'logs-admin',
    meta: { auth: 10, title: 'Logs' },
    component: () => {
      return import('@/views/admin/logsAdmin.vue');
    },
  },
  {
    path: '/change-log-admin',
    name: 'change-log-admin',
    meta: { auth: 1000, title: 'Change Logs' },
    component: () => {
      return import('@/views/admin/changeLogAdmin.vue');
    },
  },
  {
    path: '/users',
    name: 'users',
    meta: { auth: 10, title: 'Пользователи' },
    component: () => {
      return import('@/views/admin/users.vue');
    },
  },
  {
    path: '/dirs',
    name: 'dirs',
    meta: { auth: 10, title: 'Справочники' },
    props: (route) => ({ dir: route.query.dir, ...route.params }),
    component: () => {
      return import('@/views/admin/dirs.vue');
    },
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { auth: 10, title: 'Настройки' },
    component: () => {
      return import('@/views/admin/settings.vue');
    },
  },
  {
    path: '/uploadsGroup',
    name: 'uploadsGroup',
    meta: { auth: 10, title: 'Группы доступа' },
    component: () => {
      return import('@/views/admin/groupFilesAccess.vue');
    },
  },
  {
    path: '/events',
    name: 'events',
    meta: { auth: 10, title: 'Уведомления' },
    component: () => {
      return import('@/views/admin/events.vue');
    },
  },
];

export default settings;
