<template>
  <span>{{ value ? v : null }}</span>
</template>

<script>
export default {
  props: {
    value: [String],
    model: Object,
  },
  computed: {
    v() {
      const d = new Date(this.value);
      return d.toLocaleDateString("ru-RU") + " " + d.shorttime;
      return d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU");
    },
  },
};
</script>
