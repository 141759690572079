<template>
  <v-app-bar color="transparent" id="app-bar" fixed app flat height="60" style="overflow-x: auto">
    <v-btn class="mr-3" elevation="1" fab small @click="$root.leftMenuDrawer = !$root.leftMenuDrawer">
      <v-icon v-if="$root.leftMenuDrawer"> mdi-view-quilt </v-icon>
      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>
    <v-btn class="mr-3" v-if="$route.path.split('/').length > 2" fab small @click="goUp"> <v-icon>mdi-arrow-left</v-icon> </v-btn>
    <portal-target name="v-goup" />
    <v-toolbar-title class="d-none1 d-sm-flex font-weight-light" v-text="$root.title ? $t($root.title) : $root.config.appName" />
    <portal-target name="v-add" />

    <v-spacer />
    <portal-target name="v-info" />
    <widget-uploads-index />
    <widget-notifi-index />
    <information-Menu :name="infoName" />
    <phone-caller
      v-if="$root.profile.sip_can && 0 == 1"
      :user="$root.profile.sip_number"
      :ws="$root.profile.sip_ws"
      :server="$root.profile.sip_server"
      :password="$root.profile.sip_password"
    />
    <btn-avatar />
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$root.title ? this.$t($root.title) : this.$root.config.appName;
    },
    infoName() {
      let res = this.$route.path.split("/")[1];
      return res;
    },
  },
  methods: {
    goUp() {
      if (this.$route.meta.goUp) {
        this.$router.push(this.$route.meta.goUp);
        return;
      }
      var upperPath = this.$route.path.split("/");
      if (upperPath.length > 2) {
        upperPath.splice(upperPath.length - 1);
        this.$router.push(upperPath.join("/"));
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
