<template>
  <v-card>
    <a-loader v-if="loading" />
    <v-card-text class="whatsapp-chat" :style="{ bottom: `${inputPanel ? textareaHeight : 0}px` }">
      <div class="whatsapp-chat-row" v-for="(el, i) of data" :key="i">
        <comment-message :item="el" :user_id="user_id" @click="onClickMessage(el, $event)" />
      </div>
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
        <v-list v-if="menuItems" class="py-0">
          <v-list-item v-for="(item, index) in menuItems" :key="index" dense :disabled="!item.enable(currentComment)" @click="onClickMenu(item)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>
      <comment-edit v-model="showEdit" :id="idEdit" :target="target" @delete="delComment($event)" />
    </v-card-text>
    <v-card-actions
      v-if="inputPanel"
      class="whatsapp-input-wrapper pa-1 blue-grey darken-3 white--text"
      ref="chat_block_action"
      style="position: absolute; max-height: 115px; bottom: 0; width: 100%"
    >
      <div class="d-flex" style="width: 100%">
        <div style="width: 100%">
          <v-textarea
            style="max-height: 110px; overflow-y: auto"
            v-if="true"
            v-model="textMessage"
            outlined
            dense
            rows="1"
            auto-grow
            rounded
            filled
            no-resize
            hide-details
            class="whatsapp-input"
            placeholder="Введите текст сообщения"
            @input="adjustTextareaHeight"
          />
          <input v-else class="whatsapp-input" v-model="textMessage" placeholder="Введите сообщение" />
        </div>
        <div class="pa-1 ml-1">
          <v-btn dark icon @click="sendMessage(textMessage)" title="Послать сообщение"><v-icon>fas fa-paper-plane </v-icon></v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getAccess, doubleClickDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect],
  components: {},
  props: {
    user_id: Number,
    id: Number,
    needUpdate: Number,
    targetName: String,
    inputPanel: { type: Boolean, default: true },
  },
  data() {
    return {
      textMessage: "",
      textareaHeight: 53,
      idEdit: null,
      loading: false,
      title: "",
      x: 0,
      y: 0,
      currentComment: 0,
      showMenu: false,
      showEdit: false,
      timer: null,
      target: { name: this.targetName },
      menuItems: [
        {
          name: "answer",
          title: "Ответить",
          action: () => {
            this.addComment(this.currentComment);
          },
          enable: () => {
            return true;
          },
        },
        {
          name: "edit",
          title: "Редактировать",
          action: () => {
            this.editComment(this.currentComment);
          },
          enable: comment => {
            return comment && comment.user_id == this.$root.profile.id;
          },
        },
        {
          name: "delete",
          title: "Удалить",
          action: () => {
            this.delComment(this.currentComment);
          },
          enable: comment => {
            return comment && comment.user_id == this.$root.profile.id;
          },
        },
      ],
      data: {},

      model: [
        { name: "content", title: "Сообщение", type: "text" },
        { name: "status", default: 1 },
      ],
      url: "/mechti/comments",
      defaults: {
        sort: { key: "createdon", order: "desc" },
        filters: { status: 1, target_name: this.targetName, target_id: this.id },
      },
    };
  },
  created() {
    this.fitchData();
  },
  mounted() {},

  computed: {
    newComment() {
      return this.$store.getters["state/getNewComment"];
    },
    mess() {
      if (!this.messages) return [];
      return this.messages.sort((a, b) => {
        return (a.date > b.date) - (a.date < b.date);
      });
    },
  },
  watch: {
    showEdit(v) {
      if (!v) {
        this.fitchData();
      }
    },
    needUpdate(v) {
      if (v) {
        this.fitchData();
      }
    },
    newComment(v) {
      this.checkNewComment();
    },
  },

  methods: {
    async sendMessage(message) {
      if (message) {
        if (!(this.id && this.targetName)) return;
        let data = {};
        data.user_id = this.$root.profile.id;
        data.target_name = this.targetName;
        data.target_id = this.id || null;
        data.parent_id = null;
        data.content = message;
        data.status = 1;

        try {
          await this.$axios.post("/mechti/comments", data);
          this.textMessage = "";
          this.fitchData();
        } catch (error) {
          console.error(error);
        }
      }
    },
    adjustTextareaHeight() {
      // Динамически вычисляем высоту textarea и обновляем `textareaHeight`
      this.$nextTick(() => {
        const textarea = this.$refs.chat_block_action;
        if (textarea) {
          this.textareaHeight = textarea.offsetHeight + 1;
        }
      });
    },
    checkNewComment(data) {
      console.log("checkNewComment", data);
      this.fitchData();
    },
    async fitchData() {
      this.loading = true;
      const params = {};
      params.filters = { target_name: this.targetName, target_id: this.id };
      const d = await this.$axios.get(this.url, { params: this.defaults });
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = !true;
    },
    onClickMenu(item) {
      if (item.action) item.action(this.currentComment);
    },
    onSingleClick(d) {
      return this.onClickMessage(d.row, d.event);
    },
    onDoubleClick(d) {
      return this.onReadMessage(d.row, d.event);
    },

    onReadMessage(el, e) {
      if (el.viewedon) return;
      this.$axios.get(this.url + "/" + el.id);
      this.$store.dispatch("state/badges", this.targetName + "_comments");
    },
    onClickMessage(el, e) {
      console.log("onClickMessage");
      if (el.viewedon || el.createdby_id == this.$root.profile.id) {
        //this.showMenu = false;
        this.$nextTick(() => {
          this.showMenu = false;
          this.currentComment = el;
          this.x = e.clientX;
          this.y = e.clientY;
        });
        clearTimeout(this.timer);
        setTimeout(() => {
          this.showMenu = true;
        }, 50);
        this.timer = setTimeout(() => {
          this.showMenu = false;
        }, 2000);
      } else {
        this.$axios.get(this.url + "/" + el.id);
        setTimeout(() => {
          this.$store.dispatch("state/badges", this.targetName + "_comments");
        }, 500);

        el.viewedon = 1;
      }
    },
    addComment(c) {
      this.target.id = this.id || null;
      this.idEdit = 0;
      this.target.parent = c || null;
      console.log("add", this.id, c, this.target);
      this.showEdit = true;
    },
    async delComment(c) {
      console.log("delete");
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Удаление",
        message: [`Вы действительно хотите удалить комментарий?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      this.loading = true;
      let response = await this.$axios.post(this.url, { id: c.id, status: 0 });
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Коментарий удален",
        });
        this.fitchData();
      }
    },
    editComment(c) {
      this.target.id = this.id || null;
      this.idEdit = c?.id || 0;
      this.target.parent = c || null;
      console.log("add", this.id, c, this.target);
      this.showEdit = true;
      this.showEdit = true;
    },
  },
};
</script>
<style lang="scss">
.whatsapp-input {
  background: #fff;
  border-radius: 6px;
  margin: 5px;
  padding: 8px 10px;
  width: 100%;
  outline: none;
  caret-color: auto;

  scrollbar-width: thin; /* Толщина скроллбара */
  scrollbar-color: #888 #f1f1f1; /* Цвет ползунка и трека */
}

.whatsapp-chat {
  position: absolute;
  overflow: auto;
  top: 0px;
  //bottom: 58px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: url(/img/bg-chat-tile-dark_a4be512e7195b6b733d9110b408f075d.png) repeat #eee;
}

.whatsapp-chat-row {
  .row-date {
    display: flex;
    justify-content: center;

    .row-date-inner {
      margin: 10px;
      padding: 5px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 0 #e2e2e2;
      border-radius: 7.5px;
    }
  }
  .chat-title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .row-message {
    padding: 0;
    margin: 2px 10px 2px 10px;
    display: flex;

    &.left {
      justify-content: left;

      .row-message-inner {
        background-color: #fff;
      }
    }

    &.right {
      justify-content: right;

      .row-message-inner {
        background-color: #d9fdd3;
      }
    }

    .row-message-inner {
      box-shadow: 0px 1px 0 #e2e2e2;
      white-space: pre-line;
      max-width: min(70vh, 80%);
      border: 1px solid transparent;
      border-radius: 7.5px;
      padding: 8px 10px 2px 10px;
    }

    .row-message-inner-date {
      font-size: 12px;
      color: #aaa;
      margin-left: 30px;
      text-align: right;
    }
  }
}
</style>
