<template>
  <v-card-text :style="`height: ${height}px;  overflow: auto;`" class="pa-0">
    <v-fab-transition>
      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addComment()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <div style="position: relative; height: 100%">
      <comment-chatview
        ref="chat"
        v-if="id"
        :style="`position: absolute; bottom: 0; top: 0; right: 0; left: 0px`"
        :id="id"
        :targetName="targetName"
        :needUpdate="needUpdate"
        :user_id="$root.profile.id"
        :inputPanel="false"
      />
    </div>
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-list v-if="menuItems" class="py-0">
        <v-list-item v-for="(item, index) in menuItems" :key="index" dense :disabled="!item.enable(currentComment)" @click="onClickMenu(item)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <comment-edit v-model="showEdit" :id="idEdit" :target="target" @delete="delComment($event)" />
  </v-card-text>
</template>

<script>
import { getAccess, doubleClickDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect],
  components: {},
  props: {
    id: Number,
    needUpdate: Number,
    targetName: String,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      idEdit: null,
      loading: false,
      title: "",
      x: 0,
      y: 0,
      currentComment: 0,
      showMenu: false,
      showEdit: false,
      target: { name: this.targetName },
      menuItems: [
        {
          name: "answer",
          title: "Ответить",
          action: () => {
            this.addComment(this.currentComment);
          },
          enable: () => {
            return true;
          },
        },
        {
          name: "edit",
          title: "Редактировать",
          action: () => {
            this.editComment(this.currentComment);
          },
          enable: comment => {
            return comment && comment.user_id == this.$root.profile.id;
          },
        },
        {
          name: "delete",
          title: "Удалить",
          action: () => {
            this.delComment(this.currentComment);
          },
          enable: comment => {
            return comment && comment.user_id == this.$root.profile.id;
          },
        },
      ],
      data: {},

      model: [
        { name: "content", title: "Сообщение", type: "text" },
        { name: "status", default: 1 },
      ],
      url: "/mechti/comments",
      defaults: {
        sort: { key: "createdon", order: "DESC" },
        filters: { status: 1, target_name: this.targetName, target_id: this.id },
      },
    };
  },
  created() {
    this.fitchData();
  },
  mounted() {},

  computed: {
    mess() {
      if (!this.messages) return [];
      return this.messages.sort((a, b) => {
        return (a.date < b.date) - (a.date > b.date);
      });
    },
  },
  watch: {
    showEdit(v) {
      if (!v) {
        this.$refs.chat.fitchData();
        return;
        this.fitchData();
      }
    },
    needUpdate(v) {
      if (v) {
        this.$refs.chat.fitchData();
        return;
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      return;
      this.loading = true;
      const params = {};
      params.filters = { target_name: this.targetName, target_id: this.id };
      const d = await this.$axios.get(this.url, { params: this.defaults });
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = !true;
    },
    onClickMenu(item) {
      if (item.action) item.action(this.currentComment);
    },
    onSingleClick(d) {
      return this.onClickMessage(d.row, d.event);
    },
    onDoubleClick(d) {
      return this.onReadMessage(d.row, d.event);
    },

    onReadMessage(el, e) {
      if (el.viewedon) return;
      this.$axios.get(this.url + "/" + el.id);
      this.$store.dispatch("state/badges", this.targetName + "_comments");
    },
    onClickMessage(el, e) {
      if (el.viewedon || el.createdby_id == this.$root.profile.id) {
        //this.showMenu = false;
        this.$nextTick(() => {
          this.showMenu = false;
          this.currentComment = el;
          this.x = e.clientX;
          this.y = e.clientY;
        });

        setTimeout(() => {
          this.showMenu = true;
        }, 50);
      } else {
        this.$axios.get(this.url + "/" + el.id);
        setTimeout(() => {
          this.$store.dispatch("state/badges", this.targetName + "_comments");
        }, 500);

        el.viewedon = 1;
      }
    },
    addComment(c) {
      this.target.id = this.id || null;
      this.idEdit = 0;
      this.target.parent = c || null;
      console.log("add", this.id, c, this.target);
      this.showEdit = true;
    },
    async delComment(c) {
      console.log("delete");
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Удаление",
        message: [`Вы действительно хотите удалить комментарий?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      this.loading = true;
      let response = await this.$axios.post(this.url, { id: c.id, status: 0 });
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Коментарий удален",
        });
        this.fitchData();
      }
    },
    editComment(c) {
      this.target.id = this.id || null;
      this.idEdit = c?.id || 0;
      this.target.parent = c || null;
      console.log("add", this.id, c, this.target);
      this.showEdit = true;
      this.showEdit = true;
    },
  },
};
</script>
<style lang="scss">
.whatsapp-chat {
  position: absolute;
  overflow: auto;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: url(/img/bg-chat-tile-dark_a4be512e7195b6b733d9110b408f075d.png) repeat #eee;
}

.whatsapp-chat-row {
  .row-date {
    display: flex;
    justify-content: center;

    .row-date-inner {
      margin: 10px;
      padding: 5px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 0 #e2e2e2;
      border-radius: 7.5px;
    }
  }
  .chat-title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .row-message {
    padding: 0;
    margin: 2px 10px 2px 10px;
    display: flex;

    &.left {
      justify-content: left;

      .row-message-inner {
        background-color: #fff;
      }
    }

    &.right {
      justify-content: right;

      .row-message-inner {
        background-color: #d9fdd3;
      }
    }

    .row-message-inner {
      box-shadow: 0px 1px 0 #e2e2e2;
      white-space: pre-line;
      max-width: min(70vh, 80%);
      border: 1px solid transparent;
      border-radius: 7.5px;
      padding: 8px 10px 2px 10px;
    }

    .row-message-inner-date {
      font-size: 12px;
      color: #aaa;
      margin-left: 30px;
      text-align: right;
    }
  }
}
</style>
