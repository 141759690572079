<template>
  <div :style="style">{{ v }}</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    v() {
      if (!this.value) return "";
      const d = new Date(this.value);
      const pt = {};
      const pd = {};
      if (!this.model?.format?.includes("fullTime")) pt["timeStyle"] = "short";
      if (this.model?.format?.includes("shortTime")) pt["timeStyle"] = "short";
      return d.toLocaleDateString("ru-RU", pd) + " " + d.toLocaleTimeString("ru-RU", pt);
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>
